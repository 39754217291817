import React from 'react';
import dynamic from 'next/dynamic';
import { DynamicComponentObject } from 'lib/cms/links';
import {
  IComponentDoctorGalleryFields,
  IComponentGreenhouseFields,
  IComponentInsuranceListFields,
  IComponentPhotoGalleryFields,
  IComponentReviewScoreFields,
  IComponentReviewTestimonialListFields,
  IMetaLocationsSharedDataFields,
  IPageDoctorFields,
  IComponentMapFields,
  IPageLocationFields,
} from 'types/contentful';
import { getReviewAverage, getReviewsWithComments, reviewFragmentToRequest } from 'lib/reviewinc';
import reviewIncClient from '../lib/reviewinc/client';
import { validateAgainstSchema } from 'lib/cms/validate';
import { doctorDisplayName, removeIfUndefined } from 'lib/util';
import cmsClient, { getAllEntries } from 'lib/cms/client';
import { ReviewScoreProps } from './Contentful/ReviewScore';
import boardClient from 'lib/greenhouse/client';
import { getJobList } from 'lib/greenhouse/api';
import { LOCATION_SELECT_FIELDS } from 'lib/consts';
import type { EntryCollection } from 'contentful';

/**
 * A dynamic component is defined as a React component and
 * a function that transforms from server-side props to
 * client-side props.
 *
 * The prepare function is used when an API call or some other
 * complex operation is needed to translate from Contentful
 * fields to props that can be consumed by the React component.
 */
export interface ComponentDef<SSP, CP = SSP> {
  component: React.ComponentType<CP>;
  prepare: (props: SSP, preview: boolean) => Promise<CP>;
}

/**
 * Define a dynamic component that doesn't require server-side
 * translation.
 */
function def<P>(comp: React.ComponentType<P>): ComponentDef<P> {
  return {
    component: comp,
    prepare: (props: P) => Promise.resolve(props),
  };
}

/**
 * Define a dynamic component that requires server-side translation.
 * A function is provided to translate from the server-side props (SSP)
 * to the client-side props (CP).
 */
function serverSideDef<SSP, CP>(
  comp: React.ComponentType<CP>,
  prepare: (props: SSP, preview: boolean) => Promise<CP>
): ComponentDef<SSP, CP> {
  return {
    component: comp,
    prepare: prepare,
  };
}

/**
 * A mapping of Contentful content types to the component definitions.  'dynamic` is
 * used to lazy load only the React components that are needed for a particular page.
 */
const components = {
  componentImageLeftTextRight: def(dynamic(() => import('./Contentful/LeftImageRightText'))),
  componentTextLeftImageRight: def(dynamic(() => import('./Contentful/RightImageLeftText'))),
  componentImageTextOffset: def(dynamic(() => import('./Contentful/ImageTextOffset'))),
  componentSimpleHeroImageLeftTextRight: def(
    dynamic(() => import('./Contentful/Hero/SimpleHeroLeftImageRightText'))
  ),
  componentSimpleHeroImageRightTextLeft: def(
    dynamic(() => import('./Contentful/Hero/SimpleHeroRightImageLeftText'))
  ),
  componentLeftImageRightAccordion: def(
    dynamic(() => import('./Contentful/ImageLeftAccordionRight'))
  ),
  componentImageWithTitle: def(dynamic(() => import('./Contentful/ImageWithTitle'))),
  componentTestimonialList: def(dynamic(() => import('./Contentful/Testimonials'))),
  componentTextOverThreeBlocks: def(dynamic(() => import('./Contentful/TextOverThreeBlocks'))),
  componentSideBySide: def(dynamic(() => import('./Contentful/SideBySideBlock'))),
  componentVideoPlayer: def(dynamic(() => import('./Contentful/VideoPlayer'))),
  componentHeroPrimaryCircle: def(dynamic(() => import('./Contentful/Hero/HeroPrimaryCircle'))),
  componentHeroImageWithTextOverlay: def(
    dynamic(() => import('./Contentful/Hero/HeroImageWithTextOverlay'))
  ),
  componentFrequentlyAskedQuestions: def(
    dynamic(() => import('./Contentful/FrequentlyAskedQuestions'))
  ),
  componentHeroInlineCircle: def(dynamic(() => import('./Contentful/Hero/HeroInlineCircle'))),
  componentLinkList: def(dynamic(() => import('./Contentful/LinkList'))),
  componentForm: def(dynamic(() => import('./Contentful/Form'))),
  componentPhotoGallery: serverSideDef(
    dynamic(() => import('./Contentful/PhotoGallery')),
    async (props: IComponentPhotoGalleryFields) => ({
      ...props,
      gallery: props.gallery.map((block) => block.fields),
    })
  ),
  componentGreenhouse: serverSideDef(
    dynamic(() => import('./Contentful/Greenhouse')),
    async (props: IComponentGreenhouseFields) => ({
      jobs: await getJobList(boardClient, props.greenhouseJobBoardId, props.brand),
      boardId: props.greenhouseJobBoardId,
    })
  ),
  componentTextOnly: def(dynamic(() => import('./Contentful/TextOnly'))),
  componentSimpleHeroTextOnly: def(dynamic(() => import('./Contentful/Hero/SimpleHeroTextOnly'))),
  componentJson: def(dynamic(() => import('./Contentful/JSONComponent'))),
  componentThreeTextCallout: def(dynamic(() => import('./Contentful/TextCallouts'))),
  componentAccordian: def(dynamic(() => import('./UI/Accordion'))),
  componentReviewScore: serverSideDef(
    dynamic(() => import('./Contentful/ReviewScore')),
    (props: IComponentReviewScoreFields): Promise<ReviewScoreProps> =>
      getReviewAverage(reviewIncClient, reviewFragmentToRequest(props.reviewsIds)).then(
        (score) => ({
          ...removeIfUndefined({ ratingStatement: props.statement }),
          reviewCount: score.count,
          reviewOutOfFive: score.fiveStarAvg,
        })
      )
  ),
  componentReviewTestimonialList: serverSideDef(
    dynamic(() => import('./Contentful/ReviewTestimonialList')),
    ({ reviewsIds, title, backgroundColor }: IComponentReviewTestimonialListFields) =>
      getReviewsWithComments(reviewIncClient, reviewFragmentToRequest(reviewsIds)).then(
        (reviews) => ({
          reviews,
          ...removeIfUndefined({ title, backgroundColor }),
        })
      )
  ),
  componentInsuranceList: serverSideDef(
    dynamic(() => import('./UI/InsuranceProviders')),
    async (fields: IComponentInsuranceListFields, preview: boolean) => {
      const { insuranceProviders, insuranceDisclaimer, insuranceSlug } = (
        await getAllEntries<IMetaLocationsSharedDataFields>(cmsClient(preview), {
          'fields.site.sys.contentType.sys.id': 'site',
          'fields.site.fields.id': fields.site.fields.id,
          content_type: 'metaLocationsSharedData',
          include: 1,
        })
      )[0]?.fields || {
        insuranceProviders: [],
      };

      return {
        insuranceProviders: insuranceProviders.map((item) => item.fields),
        ...removeIfUndefined({
          insuranceSlug,
          insuranceDisclaimer,
        }),
      };
    }
  ),

  componentMap: serverSideDef(
    dynamic(() => import('./Contentful/Map')),
    async ({ bannerText, locations, defaultZoom }: IComponentMapFields, preview: boolean) => {
      const client = cmsClient(preview);

      const allEntries = await getAllEntries<IPageLocationFields>(client, {
        content_type: 'pageLocation',
        'sys.id[in]': locations.toString(),
        select: LOCATION_SELECT_FIELDS,
      });

      const sharedLocationData: EntryCollection<IMetaLocationsSharedDataFields> = await client.getEntries(
        {
          'fields.site.sys.contentType.sys.id': 'site',
          content_type: 'metaLocationsSharedData',
          include: 0,
          select: 'fields.hideEyeIcon',
        }
      );

      const sharedLocationDataFields = sharedLocationData.items[0]?.fields || {};
      const hideEyeIcon = !!sharedLocationDataFields.hideEyeIcon;

      //By Default first location will be taken as center
      const content = allEntries[0].fields;
      return {
        ...removeIfUndefined({ bannerText, defaultZoom }),
        content: content,
        hideEyeIcon: hideEyeIcon,
        nearbyLocations: allEntries,
      };
    }
  ),

  componentDoctorGallery: serverSideDef(
    dynamic(() => import('./Contentful/PhotoGallery')),
    async ({ title, site, typeOfDoctor }: IComponentDoctorGalleryFields, preview: boolean) => {
      const doctorEntries = await getAllEntries<IPageDoctorFields>(cmsClient(preview), {
        'fields.site.sys.contentType.sys.id': 'site',
        'fields.site.fields.id': site.fields.id,
        ...removeIfUndefined({ 'fields.typeOfDoctor': typeOfDoctor }),
        order: 'fields.lastName',
        content_type: 'pageDoctor',
        include: 1,
      });
      const gallery = doctorEntries.map(
        ({ fields: { slug, titles, firstName, lastName, doctorPhoto } }) => ({
          image: doctorPhoto,
          rowOneText: doctorDisplayName({ titles, firstName, lastName }),
          link: `doctors/${slug}`,
        })
      );

      return { heading: title, imagesPerRow: 5, gallery };
    }
  ),
};

/**
 * A union type of all content types allowed for dynamic components.
 */
export type DynamicComponentTypes = keyof typeof components;

/**
 * A type that links the content type and a component definition types.
 */
export type GenericDef<
  K extends DynamicComponentTypes,
  SSP = unknown,
  CP = unknown
> = typeof components[K] & ComponentDef<SSP, CP>;

/**
 * A type guard that will cast a key to a DynamicComponentTypes if the key
 * is a content type used by a dynamic component.
 */
export function isDynamicComponent<K extends DynamicComponentTypes>(key: string): key is K {
  return Object.keys(components).includes(key);
}

/**
 * Returns the component definition for a content type.
 */
export function definitionForType<
  K extends DynamicComponentTypes,
  C extends GenericDef<K, SSP, CP>,
  SSP = unknown,
  CP = unknown
>(type: K): C {
  return components[type] as C;
}

const SITENAME_REPLACER = new RegExp(`{siteName}`, 'gi');

/**
 * stringify fields to find all references to {siteName}
 * token and replace with the current siteName
 */
function replaceSitePlaceholder<T>(fields: T, siteName: string): T {
  const stringValue = JSON.stringify(fields);
  const sanitizedSiteName = siteName.replace(/[^\w]+|\s+/g, ' ');
  const hydrated = stringValue.replace(SITENAME_REPLACER, sanitizedSiteName);
  return JSON.parse(hydrated);
}

/**
 * Return a React element respresenting the prepared dynamic component.  Site name placeholders
 * are replaced in the props, and an error component is returned instead if validation fails for
 * preview components.
 */
export function buildDynamicComponent<
  K extends DynamicComponentTypes,
  C extends GenericDef<K, unknown, CP>,
  CP
>(
  compObj: PreparedComponentObject<K, CP>,
  siteName: string,
  preview?: boolean
): React.ReactElement {
  const comp = definitionForType<K, C, unknown, CP>(compObj.type).component;

  const props = replaceSitePlaceholder(compObj.fields, siteName);

  if (preview) {
    const errors = validateAgainstSchema(compObj.type, props);
    if (errors) {
      return (
        <div className="rounded border-2 p-2 m-2 border-highlight">
          Failed to render component, validations failed:
          <ul>
            {errors.map((error, idx) => (
              <li key={idx}>- {error.message}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  return React.createElement(comp, props);
}

/**
 * A component that has been translated and is ready to be transformed into
 * a React element.
 */
export interface PreparedComponentObject<K extends DynamicComponentTypes, CP> {
  type: K;
  fields: CP;
}

/**
 * Returns a prepared component object by calling prepare on the related component defintion.
 */
export async function translateServerSideComponent<
  K extends DynamicComponentTypes,
  C extends GenericDef<K, SSP, CP>,
  SSP,
  CP
>(
  { type, fields }: DynamicComponentObject<K, C, SSP, CP>,
  preview: boolean
): Promise<PreparedComponentObject<K, CP>> {
  const def = definitionForType<K, C, SSP, CP>(type);

  const newProp = await def.prepare(fields, preview);

  return {
    type,
    fields: newProp,
  };
}
